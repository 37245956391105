<template>
    <div class="max-w-screen-tv-main-axis max-h-screen-tv-cross-axis mx-auto" v-on:click="click" >
        <Header :languageVisibility="false" :titlesVisibility="false"/>
        <div class="text-center absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <img :src="logo" alt="Logo" class="w-80 mx-auto">
            <h1 class="font-heading text-h1 text-redDamask mt-10 mb-2">{{ $t('language.selectTitle') }}</h1>
            <p class="font-body text-paragraph text-halfColonialWhite">{{ $t('language.text') }}</p>
            <Language v-on:click="langSelected()" flag class="mt-6"/>
        </div>
        <div class="absolute bottom-0 left-0 right-0 text-center mb-5">
            <p class="font-body text-paragraph text-halfColonialWhite">Created by minic studio, icons by Icons8</p>
        </div>
    </div>
</template>

<script>

import Header from "@/components/Header.vue";
import Language from '@/components/Language';
import Logo from '@/assets/mjm-logo.svg';

export default {
    name: "Tablet",
    components: {
        Header,
        Language,
    },
    mounted() {
        this.$i18n.locale = this.lang;
    },
    data(){
        return {
            baseURL: process.env.VUE_APP_AXIOS_URL,
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
            locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE,
            logo: Logo,
        }
    },
    methods: {
        langSelected() {
            // navigate to home page
            this.$router.push('/menu');
        },
    },
};
</script>
<style>
    .mt-20 {
        margin-top: 5rem;
    }
</style>